.foodCards {
  padding-bottom: var(--spacing-sm);
  :global {
    .wn-scroll-container {
      grid-auto-columns: min(70vw, 280px) !important;
      gap: var(--spacing-sm);
      justify-items: stretch;
      padding: var(--spacing-sm);
    }
  }
}
