.titleContainer {
  display: flex;
  align-items: center;

  .title {
    margin-left: var(--spacing-md);
  }
}

.viatorProducts {
  margin-top: var(--spacing-md);
  position: relative;
}
