.regenButton {
  position: absolute;
  top: 30px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;

  &:hover {
    background: transparent !important;
    color: var(--dark-text) !important;
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.97);
  }
}

.regenWarning {
  max-width: 600px;
  padding: var(--spacing-sm) 0;

  .textContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: var(--spacing-sm);
    flex-wrap: wrap;

    .text {
      width: clamp(280px, 80%, 600px);
      margin-top: var(--spacing-xxs);
      font-weight: 400;
    }
  }

  .buttonContainer {
    display: flex;
    gap: var(--spacing-xs);
    margin-top: var(--spacing-sm);
  }
}
