.wrapper {
  position: absolute;
  width: clamp(200px, 400px, 100%);
  overflow: hidden;

  text-align: left;
  max-width: unset !important;
  font-family: var(--body-font);
  z-index: 100;

  :global {
    .mapboxgl-popup-content {
      padding: 0 var(--spacing-sm) 0 0;

      .mapboxgl-popup-close-button {
        border-radius: var(--border-radius-md);
        font-size: 2rem;
        background: var(--light-background);
      }

      .wn-viator-product-card {
        border: 0;
      }
    }
  }
}
