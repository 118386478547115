.container {
  :global {
    div {
      div.wn-scroll-container {
        @media (min-width: 480px) {
          grid-auto-columns: 380px !important;
        }
      }
    }
  }
}
