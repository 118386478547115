.container {
  position: relative;
  margin: auto;
  scroll-margin-top: 100px;
  // background: var(--light-background-4);

  @media (max-width: 768px) {
    scroll-margin-top: 150px;
  }

  .mapToggleButton {
    position: fixed;
    top: var(--spacing-lg);
    right: var(--spacing-lg);
  }
}
