.dialogOverlay {
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  z-index: 100;
}

.dialogContent {
  position: relative;
  width: fit-content;
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  background: var(--light-background);

  .closeButton {
    position: absolute;
    top: var(--spacing-xs);
    right: var(--spacing-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    aspect-ratio: 1;
    border: none;
    border-radius: var(--border-radius-md);
    background: transparent;
    font-size: var(--font-size-xxl);
  }
}
