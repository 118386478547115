.infoCard {
  width: clamp(240px, 100%, 400px);
  // margin: var(--spacing-md-sm) 0 var(--spacing-md-sm) var(--spacing-xs);
  overflow: hidden;
  border: 1px solid var(--light-border);
  border-radius: var(--border-radius-md);
  transition: transform 0.2s ease-in-out;
  background-color: var(--light-background);
  box-shadow: var(--box-shadow-light);

  // &:first-of-type {
  //   margin-left: var(--spacing-sm);
  // }

  // &:last-of-type {
  //   margin-right: var(--spacing-sm);
  // }

  //   &:hover {
  //     transform: translateY(-4px);
  //   }

  .imageWrapper {
    position: relative;
    width: clamp(240px, 100%, 400px);
    aspect-ratio: 1;

    .image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  .content {
    padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-sm)
      var(--spacing-sm);

    .title {
      font-size: 1.5em;
      margin: 0 0 var(--spacing-xxs) 0;
      color: var(--dark-texŧ);
    }

    .text {
      margin: 0;
    }
  }

  .description {
    font-size: 1em;
    color: var(--mid-text-dark);
  }
}
